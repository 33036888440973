import React from 'react'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Empty = () => {
	return (
		<div className="emptyState">
			<FontAwesomeIcon icon={faInfoCircle} size="6x" />
			<h2>Input a code snippet in the left window </h2>
			<h2>and press the arrow to see your results here</h2>
		</div>
	)
}

export default Empty;