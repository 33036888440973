import React from 'react';
import Logo from '../assets/img/schema-dev.png';
import PoweredByLogo from '../assets/img/poweredbylogo.png';

const TopBar = () => {
	return (
		<header className="main-header">
			<div className='brand'>
				<a href='https://schema.dev' target={'_blank'}>
					<img className='brand-logo' src={Logo} alt={'Logo'}/>
				</a>
				<h3>Structured Data Testing Tool </h3>
			</div>
				<div className='powered-logo'>
					<span>Powered By</span>
					<a href='https://www.seoclarity.net' target={'_blank'}>
						<img src={PoweredByLogo} alt='seoclarity'/>
					</a>
				</div>
		</header>
	);
};

export default TopBar;