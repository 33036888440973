import React from 'react'
import AceEditor from 'react-ace';
import { product } from '../constants/sampleProduct';
import 'brace/mode/html';
import 'brace/theme/github';
import { faWindowClose, faTimes, faMagic } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const HELPER_TEXT = 'Enter your structured data code in JSON+LD format here and then click the arrow';

class CodeEditor extends React.Component {

	state={focused:false}

	getDialoguePopup=()=>{
		const classes = ['popup-dialogue'];
		if(this.state.focused)classes.push('hidden');
		return(
			<div className={classes.join(' ')}>
				<div children={HELPER_TEXT}/>
				<FontAwesomeIcon onClick={this.closePopup} 
					icon={faWindowClose}/>
			</div>
		);
	}

	closePopup=()=>{
		if(this.state.focused)return;
		this.setState({focused:true});
	}
	
	editorControls=()=>{
		if(!this.state.focused || !this.props.inputJSON)return '';
		return (
			<div className='editor-control'>
				<span onClick={()=>this.props.prettifyInput()}>Prettify <FontAwesomeIcon icon={faMagic}/> </span>
				<span onClick={()=>this.props.onChange('')}>Clear <FontAwesomeIcon icon={faTimes}/> </span>
			</div>
		);
	}
	render(){
		const {inputJSON,onChange} = this.props;
		return(
						<div className='editor'>
							{this.getDialoguePopup()}
							{this.editorControls()}
							<AceEditor
								className={this.state.focused?'':'dummy-data'}
								onFocus={this.closePopup}
								placeholder={HELPER_TEXT}
								fontSize={14} mode="html"
								defaultValue={product} value={inputJSON}
								theme='github' wrapEnabled = {true}
								onChange={onChange} editorProps={{ $blockScrolling: true }}
								showPrintMargin={false}
							/>
						</div>
		);
	}
}

export default CodeEditor;