export const product = `<script type="application/ld+json">
{
	"@context": "http://www.schema.org",
	"@type": "Product",
	"brand": "Adobe Inc",
	"name": "Adobe Photoshop",
	"alternateName": "Photoshop",
	"url": "https://www.adobe.com/photoshop/",
	"description": "Adobe Photoshop is a raster graphics editor developed and published by Adobe Inc. for Windows and macOS. It was originally created in 1988 by Thomas and John Knoll. Since then, this software has become the industry standard not only in raster graphics editing, but in digital art as a whole.",
	"logo": "https://upload.wikimedia.org/wikipedia/commons/thumb/a/af/Adobe_Photoshop_CC_icon.svg/66px-Adobe_Photoshop_CC_icon.svg.png",
	"image": [
		"https://example.com/photos/4x3/photo.jpg",
		"https://example.com/photos/16x9/photo.jpg"
	],
	"offers": {
		"@type": "Offer",
		"url": "https://example.com/anvil",
		"priceCurrency": "USD",
		"price": "119.99",
		"priceValidUntil": "2020-11-05",
		"itemCondition": "https://schema.org/UsedCondition",
		"availability": "https://schema.org/InStock",
		"seller": {
		"@type": "Organization",
			"name": "Executive Objects"
		}
	}
}
</script>`
