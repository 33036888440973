import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tabs as AntTabs } from 'antd';
const { TabPane } = AntTabs;

export function Tabs({tabs=[],activeTab,setActiveTab}) {
  return (
    <AntTabs onChange={setActiveTab} defaultActiveKey={activeTab}>
      {
        tabs.map(item=>
          <TabPane tab={
              <span className='tab-item'>
                <FontAwesomeIcon icon={item.icon}/>
                {item.label}
              </span>
            } key={item.key} children={item.children}/>
          )
      }
    </AntTabs>
  );
}