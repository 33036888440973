import React from 'react'
import {faExclamationTriangle  } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const getType = (val) => {
	return typeof(val);
}
export const isInputEmpty = (input) => {
	if (input.trim() === "")
		return true
	return false
}
export const isEmptyStructure = (structure) => {
	for(let item in structure){
		if(structure[item].length>0)
			return false
	}
	return true
}
export const unsupported_type_flag = ()=>{
	return <div title="Unsupported data type"><FontAwesomeIcon icon={faExclamationTriangle}/></div>
}

export const hasError = (errorCount) => {
	if (errorCount > 0)
		return 'mark_as_error'
}
export const hasWarning = (warningCount) => {
	if(warningCount > 0)
		return 'mark_as_warning'
}