import React from 'react';
import { ToastContainer} from 'react-toastify';
import { product } from '../constants/sampleProduct'
import SingleGroup from "../components/SingleGroup"
import Aux from "../Utils/aux";
import Empty from '../components/emptyState'
import {Accordion,AccordionItem,AccordionItemButton,AccordionItemHeading,AccordionItemPanel}from 'react-accessible-accordion';
import CodeEditor from '../components/codeEditor'
import ValidateButton from '../components/validateButton'
import {getType,isEmptyStructure,unsupported_type_flag,hasError,hasWarning} from '../Utils/helpers'
import { DownloadResult } from '../components/downloadResult';
import { postJSON, postURL, prettifyJSON } from './helper';
import { faCode, faLink } from '@fortawesome/free-solid-svg-icons';
import {Tabs} from '../components/Tabs';
// import { UrlTestForm } from '../components/UrlTestForm';
import BuilderAd from '../components/BuilderAd';

const errorField = "errors";
let recursion_level = 0; let errorCount; let warningCount;
class App extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			inputJSON: product, output: {},
			isLoading: false, warningCount:0,
			isLoadingFromURL:false,activeTab:1
		}
		this.postJSON = postJSON.bind(this);
		this.postURL = postURL.bind(this);
	}
	parseError = (currentJSON, errors) => {
		for (let errorIdx in errors)
			currentJSON.push({ value: errors[errorIdx]["message"], "error": true })
	}
	onChange = (newValue) => {this.setState({inputJSON: newValue})}
	
	combineTypes = (response) => {
		let components = [];
		for(let struct_type in response)
			components.push(this.combineNodes(response[struct_type],struct_type));
		return components;
	}
	combineNodes = (data,type) => {
		let treeData = [],uuid=0;
		for (let json in data) {
			errorCount = 0;uuid++;warningCount = 0;
			let content = this.convertJSON(data[json]);
			treeData.push(
				<AccordionItem uuid={uuid}>
					<AccordionItemHeading>
						<AccordionItemButton>
							<span>{data[json]["@type"]}</span>
							<span> {data[json]['is_unsupported'] && unsupported_type_flag() } </span>
							<span> {type} </span>
							<span className={hasError(errorCount)}>{errorCount} Errors</span>
							<span className={hasWarning(warningCount)}>{warningCount} Warnings</span>
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel><ul className="list-group">{content}</ul></AccordionItemPanel>
				</AccordionItem>
			);
		}
		recursion_level = 0
		return treeData
	}
	convertJSON = (json) => {
		let component = []
		recursion_level++
		for (let field in json) {
			if (field === errorField) {
				component.push(this.handleErrorAndWarning(json,field));
				continue;
			}
			if (getType(json[field]) === "object") 
				component.push(this.handleObject(json,field));
			else if (getType(json[field]) === "array") 
				component.push(this.handleArray(json[field],field));
			else 
				component.push(<SingleGroup offset={recursion_level} fieldName={field} fieldVal={json[field]}/>);
		}
		recursion_level--;
		return component
	}
	handleErrorAndWarning =(json,field) => {
		let component = []
		for (let i = 0; i < json[field].length; i++) {
			component.push(
				<SingleGroup offset={recursion_level} fieldName={json[field][i]['field']}
					issue={json[field][i]} fieldVal={json[field][i]['message']} />)
			if (json[field][i]['severity']==="error") errorCount++;
			else warningCount++;
		}
		return component
	}
	handleObject =(json,field) => {
		let component = []
		if (json[field] && json[field].hasOwnProperty("type") && json[field]["type"] === "singleValue") {
			for (let subItem in json[field]) {
				if (json[field][subItem] === "singleValue") {
					component.push(<SingleGroup offset={recursion_level} fieldName={field}
						issue={json[field]['message']} fieldVal={json[field]['message']} />)
					errorCount++
				}
			}
		} else {
		component.push(<SingleGroup offset={recursion_level} fieldName={field} />)
			component.push(this.convertJSON(json[field]))
		}
		return component
	}
	handleArray = (arr,field) => {
		let component = []
		for (let i = 0; i < arr.length; i++) {
			if (getType(arr[i]) === "string")
				component.push(
					<SingleGroup offset={recursion_level} fieldName={field}
						fieldVal={arr[i]} />
				)
			else if (getType(arr[i]) === "array")
				component.push(this.convertJSON(arr))
			else if (getType(arr[i]) === "object") {
				if(arr[i].hasOwnProperty("type") && arr[i]["type"] === "singleValue") {
					component.push(<SingleGroup offset={recursion_level} fieldName={field}
						issue={arr[i]['message']} fieldVal={arr[i]['message']} />)
					errorCount++
				} else{
					component.push(<SingleGroup offset={recursion_level} fieldName={field} />)
					component.push(this.convertJSON(arr[i]))
				}
			}
		}
		return component
	}

	renderOutput=()=>{
		const results=({preExpanded=[]})=>{
			return (
				<Accordion preExpanded={preExpanded} 
					allowMultipleExpanded = {true} 
					allowZeroExpanded = {true}>
				{this.combineTypes(this.state.output)}
				</Accordion>
			);
		}
		return <div className="output">
			<DownloadResult getData={results} data={this.state.output} />
			{results({})}
			{isEmptyStructure(this.state.output)&&<Empty/>}
		</div>
	}
	prettifyInput=()=>{
		this.setState({inputJSON:prettifyJSON(this.state.inputJSON)});
	}

	getTabsConfiguration=()=>([{
			key:1,
			label:'Code',
			icon:faCode,
			children:<div className='input-wrapper'><CodeEditor prettifyInput={this.prettifyInput} 
			inputJSON={this.state.inputJSON} onChange={this.onChange}/></div>
		},
		{
			key:2,
			label:'URL',
			icon:faLink,
			children:<div className='input-wrapper'><BuilderAd/></div>
			// children:<div className='input-wrapper'><UrlTestForm loading={this.state.isLoadingFromURL} postURL={this.postURL}/></div>
		}
	]);
	setActiveTab=activeTab=>this.setState({activeTab});
	render() {
		return (
			<Aux>
				<main className="editor-wrapper">
					<div className="editor-wrapper__inner">
						<div className="row">
							<div className="col-lg-6">
								<div className='editor-container'>
									<Tabs setActiveTab={this.setActiveTab} tabs={this.getTabsConfiguration()} activeTab={this.state.activeTab}/>
								</div>
							</div>
							<div className="col-lg-6">
								{this.renderOutput()}
							</div>
						</div>
						<ValidateButton show={this.state.activeTab==1} isLoading={this.state.isLoading} postJSON={this.postJSON}/>
					</div>
				</main>
				<ToastContainer
					position="top-center" autoClose={2000}
					hideProgressBar={false} newestOnTop={false}
					closeOnClick rtl={false} pauseOnVisibilityChange
					draggable pauseOnHover/>
			</Aux>
		);
	}
}
export default App;