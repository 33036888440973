import {showErrorMessageFromResp,showWarningMessage} from '../Utils/Toast';
import axios from 'axios';
import { serverURL } from '../config';
import {isInputEmpty,isEmptyStructure} from '../Utils/helpers';
// import $ from 'jquery';
import Pretty from 'pretty';

export function postURL(url, userAgent){
  if(!url)return showWarningMessage("URL not found");
  this.setState({isLoadingFromURL: true});
  axios.post(serverURL,{"url":url,"user_agent":userAgent})
  .then(response => {
    this.setState({
      output: response.data.data,
      // inputJSON:response.data.html,
      isLoadingFromURL: false,
    });
    if(isEmptyStructure(this.state.output))
      showWarningMessage("No data found on URL");
  }).catch(error => {
    this.setState({isLoadingFromURL: false});
    showErrorMessageFromResp(error);
  });
}

export function postJSON(){
  if (!isInputEmpty(this.state.inputJSON) && !this.state.isLoading) {
    this.setState({isLoading: true})
    axios.post(serverURL, {"html": this.state.inputJSON})
    .then(response => {
      this.setState({output: response.data.data,isLoading: false});
      if(isEmptyStructure(this.state.output))
        showWarningMessage("No data found in code snippet");
    }).catch(error => {
      this.setState({ isLoading: false, output:{}});
      showErrorMessageFromResp(error);
    });
  } else showWarningMessage("Input is empty");
}

export function prettifyJSON(str=''){
  // const html=$.parseHTML(str);
  // if(!html.length)return str;
  // const scr=$(html).filter('script[type="application/ld+json"]');
  // [...scr].map(item=>{
  //   if(item.innerHTML){
  //     try{
  //       const beutified = '\n'+JSON.stringify(JSON.parse(item.innerHTML),undefined,3)+'\n';
  //       str=str.replace(item.innerHTML,beutified);
  //     }catch(e){}
  //   }
  // });
  return Pretty(str,{ocd: true});
}