import React from 'react';
import './index.scss';
import { Button } from 'react-bootstrap';
import BuilderLogo from '../../assets/img/builder-logo.png';
import FooterImage from '../../assets/img/bottom-image.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faStar } from '@fortawesome/free-solid-svg-icons';
/*eslint-disable*/

export default function(props) {
  return (
    <div className='builder-tab'>

      <div className='builder-tab--header'>
        <h2>Test any Page</h2>
        <p>Install the Chrome extension to build, test and validate structured data on any page.</p>
      </div>

      <div className='builder-tab--content'>
        <div className='ad-logo'>
          <img src={BuilderLogo} alt='schema builder'/>
        </div>
        <div className='ad-content'>
          <h2>Schema Builder & Tester Chrome Extension</h2>
          <p>Generate perfect structured data for any webpage in minutes</p>
          <div className='ad-content__data'>
            <div>
              <FontAwesomeIcon icon={faStar}/>
              <FontAwesomeIcon icon={faStar}/>
              <FontAwesomeIcon icon={faStar}/>
              <FontAwesomeIcon icon={faStar}/>
              <FontAwesomeIcon icon={faStar}/>
            </div>
            <div>
              <a>Developer Tools</a>
            </div>
            <div>
              <FontAwesomeIcon icon={faUser}/> 2,000+ users
            </div>
          </div>
          <div className='ad-content__install'>
              <a target={'_blank'} href='https://chrome.google.com/webstore/detail/schema-builder-for-struct/klohjdodjjeocpbpadmkcndjoadijgjg'>
            <Button variant='success'>
              Install from the Chrome Store
            </Button>
              </a>
          </div>
        </div>
      </div>
      <div className='builder-tab--footer'>
          <img src={FooterImage} alt=''/>
      </div>
    </div>
  );
}