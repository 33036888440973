import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import $ from 'jquery';

const TOPMARGIN = 20;
const LEFTMARGIN = 15;

export async function downloadAsPDF(wrapperId,itemClass){

  return  new Promise (async (resolve,reject)=>{
    try{
      const wrapper = $('#'+wrapperId);
      let items = wrapper.find('.'+itemClass);
      let htmlWidth = items.width();
      let pdfWidth = htmlWidth+(LEFTMARGIN*2);
      let pdfHeight = (pdfWidth*1.5)+(TOPMARGIN*2);
      const pdf = new jsPDF('p','pt',[pdfWidth,pdfHeight]);

      for (let index = 0; index < items.length; index++) {
        const element = items[index];
        await elToPdfPage(pdf,element);
        if(index===items.length-1) 
          pdf.save(`schema-validator_${new Date().toString()}.pdf`);
        else pdf.addPage();
      }
      resolve();
    }catch(e){reject()}
  });
}

async function elToPdfPage(pdf,element){
  let htmlWidth = $(element).width();
  let htmlHeight = $(element).height();
  let pdfWidth = htmlWidth+(LEFTMARGIN*2);
  let pdfHeight = (pdfWidth*1.5)+(TOPMARGIN*2);
  let canvasImageWidth = htmlWidth;
  let canvasImageHeight = htmlHeight;
  let additionalPages = Math.ceil(htmlHeight/pdfHeight)-1;

  const canvas = await html2canvas(element,{
    useCORS: true,
    allowTaint:true
  });
  canvas.getContext('2d');
  const imgData = canvas.toDataURL('image/png',1.0);
  pdf.addImage(imgData, 'JPG', LEFTMARGIN, TOPMARGIN,
  canvasImageWidth,canvasImageHeight);
  for (var i = 1; i <= additionalPages; i++) {
    pdf.addPage(pdfWidth, pdfHeight);
    pdf.addImage(imgData, 'JPG', LEFTMARGIN, -(pdfHeight*i)+(TOPMARGIN),
    canvasImageWidth,canvasImageHeight);
  }
}