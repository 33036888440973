import React from 'react';
import TopBar from "../components/TopBar";
import App from './App';
import './style.scss';

export default function(){
  return (
    <React.Fragment>
				<TopBar/>
        <App/>
        <footer className='main-footer'>
          <div>Powered by 
            <a target={'_blank'} href='https://www.seoclarity.net'> seoClarity</a>
          </div>
        </footer>
    </React.Fragment>
  );
}