import React from 'react'
import { faTimesCircle,faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const getClassNames = (issue) => {
    let list = []
    if (issue && issue['severity']==='error') {
        list.push("mark_as_error")
    }else if (issue && issue['severity']==='warning'){
        list.push("mark_as_warning")
    }else if (issue){ //for 'singleValue' type which is also an error
        list.push("mark_as_error")
    }
    return list.join(" ")
}

const hasIssue = (issue) => {
    if (issue && issue['severity']==='error'){
        return (
            <FontAwesomeIcon className="errorIcon" size="lg" icon={faTimesCircle} />
        )
    }else if (issue && issue['severity']==='warning')  {
        return (
            <FontAwesomeIcon className="errorIcon" size="lg" icon={faExclamationTriangle} />
        )
    }else if (issue){ //for 'singleValue' type which is also an error
        return (
            <FontAwesomeIcon className="errorIcon" size="lg" icon={faTimesCircle} />
        )
    }
    return null
}

const SingleGroup = ({ offset, issue, fieldName, fieldVal }) => {
    const offset_amount = "offset-md-" + offset + " custom-column"
    return (
        <li className="list-group-item">
            <div className="row">
                <div className="col-lg-6">
                    <div className={offset_amount}>
                        <p className={getClassNames(issue)}>{hasIssue(issue)} {fieldName}</p>
                    </div>
                </div>
                <div className="col-lg-6">
                    <p className = {getClassNames(issue)}>{fieldVal}</p>
                </div>
            </div>
        </li>
    )
}

export default SingleGroup;