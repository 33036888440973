import { faDownload, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal } from "react-bootstrap";
import React from 'react';
import { downloadAsPDF } from '../Utils/htmlToPdf';

const WRAPPERID='downloadable-items';
const ITEMCLASS='accordion__item';

export class DownloadResult extends React.Component{

  state={visible:false,downloading:false}

  toggleVisibility=()=>{
    this.setState({visible:!this.state.visible});
  }

  download=()=>{
    this.setState({downloading:true});
    downloadAsPDF(WRAPPERID,ITEMCLASS)
    .finally(()=>this.setState({downloading:false}));
  }

  modalContent=()=>{
    const preExpanded=Array(getResultLength(this.props.data)).fill(0).map((_,i)=>i+1);
    return(
      <div className='modal-content'>
        <Button variant='success' onClick={this.download}>
          {this.state.downloading
          ?<FontAwesomeIcon icon={faSpinner} spin/>  
          :<FontAwesomeIcon icon={faDownload}/> 
        }
          Download as PDF
        </Button>
        <div className='items' id='downloadable-items'
          children={this.props.getData({preExpanded})}/>
      </div>
    );
  }

  render(){
    if(!getResultLength(this.props.data))return '';
    
    return (
        <React.Fragment>
          <Button onClick={this.toggleVisibility} 
            variant='info'><FontAwesomeIcon icon={faDownload}/>
            Download Result
          </Button>
          <Modal
            backdrop={this.state.downloading?'static':true}
            closeButton
            dialogClassName='modal-80'
            onHide={this.toggleVisibility} 
            show={this.state.visible} children={this.modalContent()}/>
        </React.Fragment>
    );
  }
}

function getResultLength(data){
  return ((data||{})['json-ld']||[]).length;
}