import React from 'react'
import { faSpinner, faPlay } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ValidateButton = ({isLoading,postJSON,show}) => {
	if(!show)return '';
	return (
		<div id="validate" className=" add-btn" tabIndex={0}>
			<button disabled={isLoading}
				onClick={postJSON} className="btn btn-success play-button">
				{!isLoading && <FontAwesomeIcon icon={faPlay} size="lg" /> }
				{isLoading && <FontAwesomeIcon icon={faSpinner} size="2x" spin />}
			</button>
		</div>
	)
}

export default ValidateButton;