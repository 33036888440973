import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const showErrorMessageFromResp = (error) => {
    let status = error && error.response && error.response.status;
    console.log(error.response)
    if (status == 400){
        toast.warn(error.response.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        })
    }else{
    let message =error && error.response && error.response.data.message || "Something went wrong. Please try again.";
    if(status==403)message = 'You have exceeded your rate limit, Please try again later.';
        toast.error(message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }
}
export const showWarningMessage = (warning) => {
	toast.warn(warning, {
		position: "top-center",
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
	})
}